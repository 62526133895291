import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import VoiceCalling from "assets/images/voice_calling.svg";
import VideoCalling from "assets/images/video_calling.svg";
import Utility from "utils/Utility";

import "styles/components/NavBar.scss";

type Props = {
    title: string;
    showPC?: boolean;
    showBack: boolean;
    showRightButton?: boolean;
    rightButtonLabel?: string;
    onClickRightButton?: () => void;
    onClickVoiceCallingButton?: () => void;
    onClickVideoCallingButton?: () => void;
};
export const NavBar: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("===== NavBar");
        const navigate = useNavigate();
        const dispatch = useDispatch();

        /**
         * 右側ボタン押下時
         */
        function onClickRightButton() {
            if (props.onClickRightButton == null) {
                return;
            }
        }
        /**
         * 戻るボタン押下時
         */
        function onClickBack() {
            navigate(-1);
        }

        function getTitleClassName() {
            let className = "navbar-title";
            if (props.showBack) {
                className = className + " " + "show-back";
            }
            if (props.showRightButton) {
                className = className + " " + "show-right-button";
            }
            return className;
        }
        return (
            <div className={props.showPC !== true ? "NavBar hidden" : "NavBar"}>
                <div className="dummy-for-sp" />
                <div className="navbar-area">
                    <div className="navbar-dummy-area"></div>
                    <div className="navbar-contents">
                        <h1 className={getTitleClassName()}>{props.title}</h1>
                        <Box
                            className="back-button-area"
                            onClick={onClickBack}
                            sx={{
                                display: {
                                    xs:
                                        navigate.length === 0 ||
                                        props.showBack === false
                                            ? "none"
                                            : "flex",
                                    sm: "none",
                                },
                            }}
                        >
                            <img
                                className="img-back"
                                src="/images/ic_caret_left.png"
                                alt="Back Button"
                            />
                            <div>
                                <FormattedMessage id={"btn_back"} />
                            </div>
                        </Box>
                        {props.showRightButton === true &&
                            props.rightButtonLabel != null &&
                            props.rightButtonLabel.length > 0 && (
                                <div className="right-button-area">
                                    <button
                                        className="btn-action"
                                        onClick={onClickRightButton}
                                    >
                                        {props.rightButtonLabel}
                                    </button>
                                </div>
                            )}
                        {props.onClickVoiceCallingButton != null &&
                            props.onClickVideoCallingButton != null && (
                                <div className="call-button-area">
                                    <img
                                        src={VoiceCalling}
                                        onClick={props.onClickVoiceCallingButton}
                                    />
                                    <img
                                        src={VideoCalling}
                                        onClick={props.onClickVideoCallingButton}
                                    />
                                </div>
                            )}
                    </div>
                    <div className="navbar-dummy-area"></div>
                </div>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.title !== nextProps.title) {
            return false;
        }
        if (prevProps.showPC !== nextProps.showPC) {
            return false;
        }
        if (prevProps.showBack !== nextProps.showBack) {
            return false;
        }
        if (prevProps.showRightButton !== nextProps.showRightButton) {
            return false;
        }
        if (prevProps.rightButtonLabel !== nextProps.rightButtonLabel) {
            return false;
        }
        if (prevProps.onClickRightButton !== nextProps.onClickRightButton) {
            return false;
        }
        return true;
    }
);
