import * as React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Button,
    MenuItem,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Select,
    List,
    ListItemButton,
    ListItemText,
    Collapse,
} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { SelectChangeEvent } from "@mui/material/Select";
import { Link as RouterLink } from "react-router-dom";
import { EventSubscription } from "fbemitter";
import AppTitleImage from "../assets/images/AppTitle.png";
import { getLanguageCode, getUnreadCountMail, getUnreadCountTalk, getEmitter } from "redux/Selectors";
import {
    UserAction,
    LanguageAction,
    HistoryAction,
    UnreadCountAction,
    SelectedItemAction,
    WorkingItemAction,
    NetworkAction,
} from "redux/Actions";
import { useNavigate } from "react-router-dom";
import { AppConstants } from "constants/Constants";
import { UserRequest } from "api/requests/Requests";
import { getUserListHistory } from "redux/Selectors";
import { UserModel, HistoryUserList } from "models/Models";
import Utility from "utils/Utility";
import { AppServer } from "socket/AppServer";
import { MODE } from "constants/AppConstants";
import "styles/components/AppHeaderBar.scss";

type Props = {
    loginUser: UserModel | null;
};

export const AppHeaderBar: React.FC<Props> = React.memo(
    (props) => {
        Utility.log("===== AppHeaderBar");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const lang = useSelector((state) => getLanguageCode(state));
        const tmpUnreadMailCount = useSelector((state) => getUnreadCountMail(state));
        const unreadMailCount = tmpUnreadMailCount != null
                ? tmpUnreadMailCount
                : 0;
        const tmpUnreadTalkCount = useSelector((state) => getUnreadCountTalk(state));
        const unreadTalkCount = tmpUnreadTalkCount != null
                ? tmpUnreadTalkCount
                : 0;
        const userListHistory = useSelector((state) => getUserListHistory(state) as HistoryUserList);

        /***** useRef */
        // EventEmitter
        const emitter = useSelector((state) => getEmitter(state));
        // EventEmitterの購読
        const lstSubscription = React.useRef<EventSubscription[]>();

        /***** useState */
        const [burgerMenuOpen, setBurgerMenuOpen] = React.useState<boolean>(false);
        const [langMenuOpen, setLangMenuOpen] = React.useState<boolean>(false);
        const [anchorElProfMenu, setAnchorElProfMenu] =
            React.useState<null | HTMLElement>(null);
        // ダイアログ表示フラグ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);

        const goToTalkScreen = React.useCallback((partnerId: number) => {
            navigate(`/conversation/talk/${partnerId}`);
        }, [navigate]);

        /**
         * EventEmitterのイベント登録
         * @returns
         */
        const registerEmitterListener = React.useCallback(() => {
            if (emitter == null) {
                return;
            }
            const s = emitter.addListener(
                "GO_TO_TALK_SCREEN",
                (partnerId: number) => {
                    goToTalkScreen(partnerId);
                }
            );
            lstSubscription.current = [s];
        }, [
            emitter,
            goToTalkScreen
        ]);

        /**
         * EventEmitterのイベント解除
         */
        const removeEmitterListener = React.useCallback(() => {
            if (lstSubscription != null && lstSubscription.current != null) {
                for (let i = 0; i < lstSubscription.current.length; i++) {
                    lstSubscription.current[i].remove();
                }
            }
            if (emitter != null) {
                emitter.removeAllListeners("GO_TO_TALK_SCREEN");
            }
        }, [emitter]);
        
        /***** useEffect */
        React.useEffect(() => {
            Utility.log("AppHeaderBar useEffect 1");
            // EventEmitterを登録
            registerEmitterListener();
            return () => {
                removeEmitterListener();
            };
        }, [
            emitter,
            registerEmitterListener,
            removeEmitterListener,
        ]);
            

        /**
         * バーガーメニュー開く
         * @param event
         */
        const handleOpenProfMenu = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElProfMenu(event.currentTarget);
        };
        /**
         * バーガーメニュー閉じる
         */
        const handleCloseProfMenu = () => {
            setAnchorElProfMenu(null);
        };

        /**
         * メール未読件数取得
         * @returns
         */
        async function getUnreadMailCount() {
            if (AppConstants.mode === MODE.PRODUCTION) {
                const unreadCount = await Utility.getUnreadMailCount(
                    props.loginUser
                );
                dispatch(UnreadCountAction({ mail: unreadCount }));
            }
        }

        /**
         * 新規登録選択時
         */
        const onClickRegister = () => {
            navigate("/register");
        };
        /**
         * ログイン選択時
         */
        const onClickLogin = () => {
            navigate("/login");
        };
        /**
         * ログアウト選択時
         */
        const onClickLogout = async () => {
            setAnchorElProfMenu(null);
            const result = await UserRequest.logout();
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
    
            if (result.rtnCd === 0) {
                AppServer.instance.disconnect();
                window.location.href = "/";
            }
        };
        /**
         * ユーザリスト選択時
         */
        const onClickUserList = async () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            if (userListHistory != null) {
                userListHistory.scrollPos = 0;
                dispatch(HistoryAction({ userListHistory: userListHistory }));
            }
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearReceivedMailListHistory();
            clearSentMailListHistory();
            navigate("/users");
        };
        /**
         * グループリスト選択時
         */
        const onClickGroupList = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearHistory();
            navigate("/groups");
        };
        /**
         * 掲示板選択時
         */
        const onClickMessageBoard = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearHistory();
            navigate("/board");
        };
        /**
         * 会話選択時
         */
        const onClickTalk = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearHistory();
            navigate("/conversation");
        };
        /**
         * メール選択時
         */
        const onClickMail = async () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearUserListHistory();
            if (window.location.pathname.includes("/mail")) {
                window.setTimeout(() => {
                    navigate("/mail", {state: {
                        refreshTime: new Date().getTime(),
                    }});
                });
            } else {
                window.setTimeout(() => {
                    navigate("/mail");
                });
            }
        };
        /**
         * マイページ選択時
         */
        const onClickMyPage = () => {
            if (props.loginUser == null) {
                setOpenDialog(true);
                return;
            }
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearHistory();
            navigate("/mypage");
        };
        /**
         * ご要望選択時
         */
        const onClickRequest = () => {
            getUnreadMailCount();
            clearSelectedItem();
            clearWorkingItem();
            clearUserListHistory();
            clearReceivedMailListHistory();
            clearSentMailListHistory();
            navigate("/report");
        };

        /**
         * 言語変更時
         * @param lang 
         */
        const onChangeLanguage = async (lang: string) => {
            setBurgerMenuOpen(false);
            dispatch(
                LanguageAction({
                    code: lang,
                })
            );
            if (props.loginUser != null &&
                props.loginUser.id != null &&
                props.loginUser.bearerToken != null
            ) {
                const result = await UserRequest.changeLanguage(
                    props.loginUser.id,
                    lang,
                    props.loginUser.bearerToken
                );
                if (result == null || result.rtnCd == null || result.rtnCd !== 0) {
                    return;
                }
                if (result.user == null) {
                    return;
                }
                props.loginUser.languageCd = lang
                dispatch(
                    UserAction({loginUser: props.loginUser})
                );
            }
        }

        const onClickBurgerMenu = () => {
            setBurgerMenuOpen((prev) => {
                return !prev;
            });
        }

        function clearSelectedItem() {
            dispatch(
                SelectedItemAction({
                    userId: 0,
                    user: null,
                    group: null,
                    parentMail: null,
                    childMail: null,
                    talkUser: null,
                    album: null,
                    lstUser: null,
                    lstGroup: null,
                    albumName: null,
                    albumPublicSetting: null,
                    albumTemplateId: null,
                })
            );
        }
        function clearWorkingItem() {
            dispatch(
                WorkingItemAction({
                    register: null,
                    album: null,
                    mail: null,
                    profile: null,
                })
            );
        }
        function clearHistory() {
            clearUserListHistory();
            clearReceivedMailListHistory();
            clearSentMailListHistory();
        }
        function clearUserListHistory() {
            dispatch(HistoryAction({ userListHistory: null }));
        }
        function clearReceivedMailListHistory() {
            dispatch(HistoryAction({ receivedMailListHistory: null }));
        }
        function clearSentMailListHistory() {
            dispatch(HistoryAction({ sentMailListHistory: null }));
        }

        /**
         * 言語設定コンポーネント
         * @returns 
         */
        const SelectLanguage = () => {
            return (
                <Select
                    className="select-language"
                    value={`${lang}`}
                    inputProps={{
                        "aria-label": "Without label",
                    }}
                    onChange={(e: SelectChangeEvent) => {
                        onChangeLanguage(e.target.value);
                    }}
                >
                    <MenuItem value="ja">
                        {intl.formatMessage({
                            id: "language_ja",
                        })}
                    </MenuItem>
                    <MenuItem value="en">
                        {intl.formatMessage({
                            id: "language_en",
                        })}
                    </MenuItem>
                    <MenuItem value="cn">
                        {intl.formatMessage({
                            id: "language_cn",
                        })}
                    </MenuItem>
                    <MenuItem value="tw">
                        {intl.formatMessage({
                            id: "language_tw",
                        })}
                    </MenuItem>
                    <MenuItem value="es">
                        {intl.formatMessage({
                            id: "language_es",
                        })}
                    </MenuItem>
                    <MenuItem value="de">
                        {intl.formatMessage({
                            id: "language_de",
                        })}
                    </MenuItem>
                    <MenuItem value="it">
                        {intl.formatMessage({
                            id: "language_it",
                        })}
                    </MenuItem>
                    <MenuItem value="ru">
                        {intl.formatMessage({
                            id: "language_ru",
                        })}
                    </MenuItem>
                </Select>
            );
        }
        return (
            <div className="AppBarArea">
                <div className="appBarAreaDummy" />
                <AppBar className="app-bar">
                    <Container className="app-bar-container" maxWidth={false}>
                        <Toolbar className="tool-bar" disableGutters>
                            <Link href="/" className="link">
                                <>
                                    {
                                        // アプリタイトル画像(PC用)
                                    }
                                    <div className="app-icon-area pc">
                                        <img
                                            id="app_icon"
                                            src={AppTitleImage}
                                            alt=""
                                        />
                                    </div>
                                    {
                                        // アプリタイトル画像(モバイル用)
                                    }
                                    <div className="app-icon-area sp">
                                        <img
                                            id="app_icon"
                                            src={AppTitleImage}
                                            alt=""
                                        />
                                    </div>
                                </>
                            </Link>
                            {
                                // メニュー(PC用)
                            }
                            <div className="app-menu-wrapper pc">
                                <div className="app-menu">
                                    {
                                        // ユーザ一覧(PC用)
                                    }
                                    <div className="menu-item user-list">
                                        <Button
                                            className="button"
                                            onClick={onClickUserList}
                                        >
                                            <FormattedMessage
                                                id={"MENU_USERS"}
                                            />
                                        </Button>
                                    </div>
                                    {
                                        // グループ一覧(PC用)
                                    }
                                    <div className="menu-item group-list">
                                        <Button
                                            className="button"
                                            onClick={onClickGroupList}
                                        >
                                            <FormattedMessage
                                                id={"MENU_GROUPS"}
                                            />
                                        </Button>
                                    </div>
                                    {
                                        // 掲示板(PC用)
                                    }
                                    <div className="menu-item message-board">
                                        <Button
                                            className="button"
                                            onClick={onClickMessageBoard}
                                        >
                                            <FormattedMessage
                                                id={"MENU_BULLETIN_BOARD"}
                                            />
                                        </Button>
                                    </div>
                                    {
                                        // トーク(PC用)
                                    }
                                    <div className="menu-item talk">
                                        <Button
                                            className="button"
                                            onClick={onClickTalk}
                                        >
                                            <FormattedMessage
                                                id={"MENU_CONVERSATION"}
                                            />
                                        </Button>
                                        <div
                                            className={
                                                unreadTalkCount === 0
                                                    ? "badge none"
                                                    : "badge"
                                            }
                                        >
                                            {unreadTalkCount}
                                        </div>
                                    </div>
                                    {
                                        // メール(PC用)
                                    }
                                    <div className="menu-item mail">
                                        <Button
                                            className="button"
                                            onClick={onClickMail}
                                        >
                                            <FormattedMessage
                                                id={"MENU_MAIL"}
                                            />
                                        </Button>
                                        <div
                                            className={
                                                unreadMailCount === 0
                                                    ? "badge none"
                                                    : "badge"
                                            }
                                        >
                                            {unreadMailCount}
                                        </div>
                                    </div>
                                    {
                                        // マイページ(PC用)
                                    }
                                    <div className="menu-item mypage">
                                        <Button
                                            className="button"
                                            onClick={onClickMyPage}
                                        >
                                            <FormattedMessage
                                                id={"MENU_MYPAGE"}
                                            />
                                        </Button>
                                    </div>
                                    {
                                        // ご要望
                                    }
                                    <div className="menu-item request">
                                        <Button
                                            className="button"
                                            onClick={onClickRequest}
                                        >
                                            <FormattedMessage
                                                id={"MENU_REQUEST"}
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {props.loginUser == null && (
                                <div className="app-login-register pc">
                                    <>
                                        {
                                            // 言語設定
                                        }
                                        <SelectLanguage />
                                        {
                                            // 新規登録
                                        }
                                        <Link
                                            className="register"
                                            component={RouterLink}
                                            to={"/register"}
                                        >
                                            <FormattedMessage
                                                id={"MENU_REGISTER"}
                                            />
                                        </Link>
                                        {
                                            // ログイン
                                        }
                                        <Link
                                            className="login"
                                            component={RouterLink}
                                            to={"/login"}
                                        >
                                            <FormattedMessage
                                                id={"MENU_LOGIN"}
                                            />
                                        </Link>
                                    </>
                                </div>
                            )}
                            {
                                // プロフィール画像(PC用)
                            }
                            {props.loginUser != null && (
                                <div className="user-settings-area">
                                    <SelectLanguage />
                                    <IconButton
                                        className={
                                            props.loginUser == null
                                                ? "profile-image-area pc"
                                                : "profile-image-area login pc"
                                        }
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenProfMenu}
                                        color="inherit"
                                    >
                                        <span className="username">
                                            {props.loginUser.name}
                                        </span>
                                        {props.loginUser.iconName != null && (
                                            <img
                                                className="profile-image"
                                                src={props.loginUser.getIconUrl(props.loginUser.profileImageUrlParam)}
                                                alt="Profile Icon"
                                            />
                                        )}
                                    </IconButton>
                                    {
                                        // プロフィール画像サブメニュー(PC用)
                                    }
                                    <Menu
                                        className="profile-sub-menu pc"
                                        id="menu-appbar"
                                        anchorEl={anchorElProfMenu}
                                        open={Boolean(anchorElProfMenu)}
                                        onClose={handleCloseProfMenu}
                                    >
                                        {
                                            // ログアウト(メニュー)
                                        }
                                        <MenuItem
                                            className="sub-menu-item"
                                            onClick={onClickLogout}
                                        >
                                            <Typography textAlign="center">
                                                <FormattedMessage
                                                    id={"MENU_LOGOUT"}
                                                />
                                            </Typography>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            )}
                            {
                                // バーガーメニュー(モバイル用)
                            }
                            <div className="burger-menu-icon-area">
                                <div 
                                    className="menu-icon"
                                    onClick={onClickBurgerMenu}
                                >
                                    <span className={burgerMenuOpen ? "open line" : "close line"}></span>
                                    <span className={burgerMenuOpen ? "open line" : "close line"}></span>
                                    <span className={burgerMenuOpen ? "open line" : "close line"}></span>
                                </div>
                            </div>
                            <div 
                                className={burgerMenuOpen ? "overlay show" : "overlay hidden"}
                                onClick={() => {
                                    setBurgerMenuOpen(false);
                                }}
                            />
                            <div className={burgerMenuOpen ? "burger-menu-sheet open" : "burger-menu-sheet close"}>
                                {
                                    // メニューアイテム
                                }
                                <List
                                    component="nav"
                                    className="menu-list"
                                >
                                    {
                                        // 言語選択
                                    }
                                    <ListItemButton
                                        className={langMenuOpen ? "burger-menu-item accordion open" : "burger-menu-item accordion close"}
                                        onClick={() => {
                                            setLangMenuOpen((prev) => {
                                                return !prev;
                                            });
                                        }}
                                    >
                                        <ListItemText>
                                            <FormattedMessage
                                                id={"language"}
                                            />
                                        </ListItemText>
                                        {langMenuOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={langMenuOpen} timeout="auto" unmountOnExit>
                                        <List 
                                            component="div" 
                                            disablePadding
                                            className="burger-menu-item sub"
                                        >
                                            <ListItemButton 
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    onChangeLanguage("ja");
                                                }}
                                            >
                                                <ListItemText
                                                    className={lang === "ja" ? "lang-name active" : "lang-name"}
                                                >
                                                    <FormattedMessage
                                                        id={"language_ja"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                            <ListItemButton 
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    onChangeLanguage("en");
                                                }}
                                            >
                                                <ListItemText
                                                    className={lang === "en" ? "lang-name active" : "lang-name"}
                                                >
                                                    <FormattedMessage
                                                        id={"language_en"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                            <ListItemButton 
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    onChangeLanguage("cn");
                                                }}
                                            >
                                                <ListItemText
                                                    className={lang === "cn" ? "lang-name active" : "lang-name"}
                                                >
                                                    <FormattedMessage
                                                        id={"language_cn"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                            <ListItemButton 
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    onChangeLanguage("tw");
                                                }}
                                            >
                                                <ListItemText
                                                    className={lang === "tw" ? "lang-name active" : "lang-name"}
                                                >
                                                    <FormattedMessage
                                                        id={"language_tw"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                            <ListItemButton 
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    onChangeLanguage("es");
                                                }}
                                            >
                                                <ListItemText
                                                    className={lang === "es" ? "lang-name active" : "lang-name"}
                                                >
                                                    <FormattedMessage
                                                        id={"language_es"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                            <ListItemButton 
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    onChangeLanguage("de");
                                                }}
                                            >
                                                <ListItemText
                                                    className={lang === "de" ? "lang-name active" : "lang-name"}
                                                >
                                                    <FormattedMessage
                                                        id={"language_de"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                            <ListItemButton 
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    onChangeLanguage("it");
                                                }}
                                            >
                                                <ListItemText
                                                    className={lang === "it" ? "lang-name active" : "lang-name"}
                                                >
                                                    <FormattedMessage
                                                        id={"language_it"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                            <ListItemButton 
                                                sx={{ pl: 4 }}
                                                onClick={() => {
                                                    onChangeLanguage("ru");
                                                }}
                                            >
                                                <ListItemText
                                                    className={lang === "ru" ? "lang-name active" : "lang-name"}
                                                >
                                                    <FormattedMessage
                                                        id={"language_ru"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                    {props.loginUser == null && (
                                        <>
                                            {
                                                // 新規登録(メニュー)
                                            }
                                            <ListItemButton
                                                className={
                                                    props.loginUser == null
                                                        ? "burger-menu-item"
                                                        : "burger-menu-item hidden"
                                                }
                                                onClick={() => {
                                                    setBurgerMenuOpen(false);
                                                    onClickRegister();
                                                }}
                                            >
                                                <ListItemText>
                                                    <FormattedMessage
                                                        id={"MENU_REGISTER"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                            {
                                                // ログイン(メニュー)
                                            }
                                            <ListItemButton
                                                className={
                                                    props.loginUser == null
                                                        ? "burger-menu-item"
                                                        : "burger-menu-item hidden"
                                                }
                                                onClick={() => {
                                                    setBurgerMenuOpen(false);
                                                    onClickLogin();
                                                }}
                                            >
                                                <ListItemText>
                                                    <FormattedMessage
                                                        id={"MENU_LOGIN"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                        </>
                                    )}
                                    {
                                        // ご要望(メニュー)
                                    }
                                    <ListItemButton
                                        className="burger-menu-item"
                                        onClick={() => {
                                            setBurgerMenuOpen(false);
                                            onClickRequest();
                                        }}
                                    >
                                        <ListItemText>
                                            <FormattedMessage
                                                id={"MENU_REQUEST"}
                                            />
                                        </ListItemText>
                                    </ListItemButton>
                                    {props.loginUser != null && (
                                        <>
                                            {
                                                // ログアウト(メニュー)
                                            }
                                            <ListItemButton
                                                className={
                                                    props.loginUser != null
                                                        ? "burger-menu-item"
                                                        : "burger-menu-item hidden"
                                                }
                                                onClick={() => {
                                                    setBurgerMenuOpen(false);
                                                    onClickLogout();
                                                }}
                                            >
                                                <ListItemText>
                                                    <FormattedMessage
                                                        id={"MENU_LOGOUT"}
                                                    />
                                                </ListItemText>
                                            </ListItemButton>
                                        </>
                                    )}
                                </List>
                            </div>
                        </Toolbar>
                        <Dialog
                            className="dialog"
                            open={openDialog}
                            onClose={() => {
                                setOpenDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className="dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent className="dialog-content2">
                                <DialogContentText id="alert-dialog-description">
                                    <FormattedMessage id={"msg_login_needed"} />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className="dialog-button"
                                    onClick={() => {
                                        setOpenDialog(false);
                                    }}
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </AppBar>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        const prevLoginUser = prevProps.loginUser;
        const nextLoginUser = nextProps.loginUser;
        if (prevLoginUser !== nextLoginUser) {
            return false;
        }
        if (prevLoginUser != null && nextLoginUser != null) {
            if (prevLoginUser.id !== nextLoginUser.id) {
                return false;
            }
        }
        return true;
    }
);
