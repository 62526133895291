import { UserModel } from "./UserModel";

export class TalkUserModel {
    userId: number | null = null;
    user: UserModel | null = null;
    partnerId: number | null = null;
    partner: UserModel | null = null;
    unreadCount: number | null = null;
    hidden: number | null = null;
    talkedAt: string | null = null;
    createdAt: string | null = null;
    updatedAt: string | null = null;

    talkPermitted: boolean = false;
    partnerTalkPermitted: boolean = false;
    
    constructor(obj?: any) {
        if (obj == null) {
            return;
        }
        // userId
        if (obj.hasOwnProperty("userId")) {
            this.userId = obj.userId;
        } else if (obj.hasOwnProperty("UserId")) {
            this.userId = obj.UserId;
        }
        // user
        if (obj.hasOwnProperty("user")) {
            this.user = new UserModel(obj.user);
        } else if (obj.hasOwnProperty("User")) {
            this.user = new UserModel(obj.User);
        }
        // partnerId
        if (obj.hasOwnProperty("partnerId")) {
            this.partnerId = obj.partnerId;
        } else if (obj.hasOwnProperty("PartnerId")) {
            this.partnerId = obj.PartnerId;
        }
        // partner
        if (obj.hasOwnProperty("partner")) {
            this.partner = new UserModel(obj.partner);
        } else if (obj.hasOwnProperty("Partner")) {
            this.partner = new UserModel(obj.Partner);
        }
        // unreadCount
        if (obj.hasOwnProperty("unreadCount")) {
            this.unreadCount = obj.unreadCount;
        } else if (obj.hasOwnProperty("UnreadCount")) {
            this.unreadCount = obj.UnreadCount;
        }
        // hidden
        if (obj.hasOwnProperty("hidden")) {
            this.hidden = obj.hidden;
        } else if (obj.hasOwnProperty("Hidden")) {
            this.hidden = obj.Hidden;
        }
        // talkedAt
        if (obj.hasOwnProperty("talkedAt")) {
            this.talkedAt = obj.talkedAt;
        } else if (obj.hasOwnProperty("TalkedAt")) {
            this.talkedAt = obj.TalkedAt;
        }
        // createdAt
        if (obj.hasOwnProperty("createdAt")) {
            this.createdAt = obj.createdAt;
        } else if (obj.hasOwnProperty("CreatedAt")) {
            this.createdAt = obj.CreatedAt;
        }
        // updatedAt
        if (obj.hasOwnProperty("updatedAt")) {
            this.updatedAt = obj.updatedAt;
        } else if (obj.hasOwnProperty("UpdatedAt")) {
            this.updatedAt = obj.UpdatedAt;
        }
    }
}
