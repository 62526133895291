import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import RequestResetPassword from "./pages/login/RequestResetPassword";
import UserList from "./pages/user/UserList";
import UserDetail from "./pages/user/UserDetail";
import GroupList from "./pages/group/GroupList";
import GroupCreate from "./pages/group/GroupCreate";
import GroupEdit from "./pages/group/GroupEdit";
import GroupInvite from "pages/group/GroupInvite";
import GroupDetail from "pages/group/GroupDetail";
import GroupChat from "pages/group/GroupChat";
import ManageGroupList from "pages/mypage/ManageGroupList";
import GroupMemberList from "pages/group/GroupMemberList";
import Board from "pages/board/Board";
import UserCommunication from "pages/user_communication/UserCommunication";
import Mail from "pages/mail/Mail";
import SendMessage from "pages/sp/SendMessage";
import ReceivedChildMail from "pages/sp/ReceivedChildMail";
import SentChildMail from "pages/sp/SentChildMail";
import MyPage from "pages/mypage/MyPage";
import AlbumList from "pages/mypage/AlbumList";
import AlbumCreate from "pages/mypage/AlbumCreate";
import AlbumEdit from "pages/mypage/AlbumEdit";
import GalleryList from "pages/mypage/GalleryList";
import SelectGroup from "pages/sp/SelectGroup";
import SelectUser from "pages/sp/SelectUser";
import SelectTag from "pages/sp/SelectTag";
import SelectCountry from "pages/sp/SelectCountry";
import Profile from "pages/sp/Profile";
import FollowUserList from "pages/mypage/FollowUserList";
import FollowerList from "pages/mypage/FollowerList";
import BlockUserList from "pages/mypage/BlockUserList";
import Report from "pages/report/Report";
import Shoot from "pages/common/Shoot";
import Maintenance from "pages/Maintenance";
import NotFoundPage from "pages/NotFoundPage";
import Top from "pages/Top";
import {
    AppHeaderBar,
    Footer,
    AdContentsLeftComponent,
    AdContentsRightComponent,
    AdContentsBottomComponent,
} from "components/Components";
import { UserModel } from "models/Models";
import { AppConstants } from "constants/AppConstants";
import Utility from "utils/Utility";
import "styles/Router.scss";

type Props = {
    loginUser: UserModel | null;
    maintenance: boolean | null;
};

const Router: React.FC<Props> = React.memo(
    (props) => {
        Utility.log("@@@@@ Router IN maintenance:" + props.maintenance);

        /***** 定数、変数 */
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));

        /***** useRef */

        /***** useEffect */

        return (
            <BrowserRouter>
                <Box className="mainWrapper">
                    <AppHeaderBar loginUser={props.loginUser} />
                    <div className="main">
                        <div className="main-inner-wrapper">
                            {!isSizeXS && (
                                <AdContentsLeftComponent />
                            )}
                            <Routes>
                                {
                                    // トップ
                                }
                                <Route
                                    path="/"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <Top loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                <Route
                                    path="/index.html"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <Top loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                {
                                    // 新規登録
                                }
                                <Route
                                    path="/register"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <Register />
                                        )
                                    }
                                />
                                {
                                    // ログイン
                                }
                                <Route
                                    path="/login"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <Login />
                                        )
                                    }
                                />
                                {
                                    // パスワードリセット要求
                                }
                                <Route
                                    path="/reset_password/request"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <RequestResetPassword />
                                        )
                                    }
                                />
                                {
                                    // ユーザ一覧
                                }
                                <Route
                                    path="/users"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <UserList loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                {
                                    // ユーザ詳細
                                }
                                <Route
                                    path="/users/:mark/profile"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <UserDetail
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // 招待画面
                                }
                                <Route
                                    path="/users/:mark/invite"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <GroupInvite
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // グループ一覧
                                }
                                <Route
                                    path="/groups"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <GroupList
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // グループ作成
                                }
                                <Route
                                    path="/groups/create"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <GroupCreate
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // グループ詳細
                                }
                                <Route
                                    path="/groups/:groupId"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <GroupDetail
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // グループ編集
                                }
                                <Route
                                    path="/groups/:groupId/edit"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <GroupEdit
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // グループメンバーリスト
                                }
                                <Route
                                    path="/groups/:groupId/member_list"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <GroupMemberList
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // グループチャット
                                }
                                <Route
                                    path="/groups/:group_id/chat"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <GroupChat
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // 掲示板
                                }
                                <Route
                                    path="/board"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <Board loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                {
                                    // スレッドリスト
                                }
                                <Route
                                    path="/board/:categoryId/thread/list"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <Board loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                {
                                    // スレッド投稿
                                }
                                <Route
                                    path="/board/:categoryId/thread/new"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <Board loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                {
                                    // レスポンスリスト
                                }
                                <Route
                                    path="/board/:categoryId/thread/:threadId/list"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <Board loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                {
                                    // 会話
                                }
                                <Route
                                    path="/conversation/*"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <UserCommunication
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // メール
                                }
                                <Route
                                    path="/mail"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <Mail loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                {
                                    // メール送信
                                }
                                <Route
                                    path="/mail/send"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <SendMessage
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // 受信メール詳細
                                }
                                <Route
                                    path="/mail/received/:parentId"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <ReceivedChildMail
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // 送信メール詳細
                                }
                                <Route
                                    path="/mail/sent/:parentId"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <SentChildMail
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {
                                    // マイページ
                                }
                                <Route
                                    path="/mypage"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <MyPage loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                <Route
                                    path="/mypage/album"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <AlbumList
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/mypage/album/create"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <AlbumCreate
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/mypage/album/:album_id/setting"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <AlbumEdit
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/mypage/album/:album_id/item_list"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <GalleryList
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/mypage/follow"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <FollowUserList
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/mypage/follower"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <FollowerList
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/mypage/block"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <BlockUserList
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/select/group"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <SelectGroup
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/select/user"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <SelectUser
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/select/tag"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <SelectTag
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/profile"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <Profile loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                <Route
                                    path="/mypage/group"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : props.loginUser == null ? (
                                            <Navigate replace to="/login" />
                                        ) : (
                                            <ManageGroupList
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/select/country"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <SelectCountry
                                                loginUser={props.loginUser}
                                            />
                                        )
                                    }
                                />
                                {/* <Route
                                    path="/shoot/photo"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <Shoot />
                                        )
                                    }
                                /> */}
                                <Route
                                    path="/report"
                                    element={
                                        props.maintenance ? (
                                            <Navigate to="/maintenance" />
                                        ) : (
                                            <Report loginUser={props.loginUser} />
                                        )
                                    }
                                />
                                <Route
                                    path="/maintenance"
                                    element={
                                        <Maintenance />
                                    }
                                />
                                <Route
                                    path="*"
                                    element={
                                        props.maintenance ? (
                                            <Maintenance />
                                        ) : (
                                            <NotFoundPage />
                                        )
                                    }
                                />
                            </Routes>
                            {!isSizeXS && (
                                <AdContentsRightComponent />
                            )}
                        </div>
                    </div>
                    <AdContentsBottomComponent />
                    {isSizeXS && (
                        <Footer loginUser={props.loginUser} />
                    )}
                </Box>
            </BrowserRouter>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        const prevLoginUser = prevProps.loginUser;
        const nextLoginUser = nextProps.loginUser;
        const prevMaintenance = prevProps.maintenance;
        const nextMaintenance = nextProps.maintenance;
        if (prevMaintenance !== nextMaintenance) {
            return false;
        }
        if (prevLoginUser !== nextLoginUser) {
            return false;
        }
        return true;
    }
);

export default Router;
