import React from "react";
import { FormattedMessage } from "react-intl";
import reactStringReplace from "react-string-replace";
import { Box, IconButton } from "@mui/material";
import { UserModel, TalkMessageModel } from "models/Models";
import { AttachedFileListForChatComponent } from "components/Components";
import { AppConstants, DbConstants, UrlConstants } from "constants/Constants";
import Utility from "utils/Utility";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

type Props = {
    loginUser: UserModel;
    partner: UserModel;
    talkMessage: TalkMessageModel;
    timeDifference: number;
    langCd: string;
    visible: boolean;
    read: number;
    onClickDelete: () => void;
    onRead: () => void;
};

const UserChatListItem: React.FC<Props> = (props) => {
    // Utility.log("===== UserChatListItem");
    /***** useState */
    const [isMyMessage, setIsMyMessage] = React.useState<boolean>();

    /***** useEffect */
    React.useEffect(() => {
        const talkMessage = props.talkMessage;
        if (talkMessage != null) {
            if (props.loginUser.id === talkMessage.senderId) {
                setIsMyMessage(true);
            } else {
                setIsMyMessage(false);
            }
        }
    }, [props.talkMessage]);

    /**
     * レンダー
     */
    return (
        <Box
            className="ListItemWrapper UserChatListItem"
            sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                visibility: props.visible ? "visible" : "hidden",
            }}
        >
            {props.loginUser.name != null && props.partner.name != null && (
                <>
                    {
                        // 日付ヘッダーの場合
                    }
                    {props.talkMessage.isHeader && (
                        <DateHeader talkMessage={props.talkMessage} />
                    )}
                    {
                        // トークメッセージの場合(マイメッセージ)
                    }
                    {!props.talkMessage.isHeader && isMyMessage && (
                        <MyTalkMessage
                            loginUser={props.loginUser}
                            talkMessage={props.talkMessage}
                            partner={props.partner}
                            onClickDelete={props.onClickDelete}
                        />
                    )}
                    {
                        // トークメッセージの場合(パートナーメッセージ)
                    }
                    {!props.talkMessage.isHeader && !isMyMessage && (
                        <PartnerTalkMessage
                            loginUser={props.loginUser}
                            talkMessage={props.talkMessage}
                            partner={props.partner}
                            onRead={props.onRead}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

/**
 * 日付ヘッダーコンポーネント
 */
type DateHeaderProps = {
    talkMessage: TalkMessageModel;
};
const DateHeader: React.FC<DateHeaderProps> = React.memo(({ talkMessage }) => {
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: "10px",
            }}
        >
            <Box
                sx={{
                    color: "#ffffff",
                    backgroundColor: "#cc88ff",
                    padding: "2px 10px",
                    borderRadius: "9999px",
                }}
            >
                {talkMessage.displayDate}
            </Box>
        </Box>
    );
});

/**
 * マイメッセージコンポーネント
 * @param chatMessage
 */
type MyTalkMessageProps = {
    loginUser: UserModel;
    talkMessage: TalkMessageModel;
    partner: UserModel;
    onClickDelete: () => void;
};
const MyTalkMessage: React.FC<MyTalkMessageProps> = (props) => {
    if (props.talkMessage == null) {
        return <></>;
    }
    const talkMessage = props.talkMessage;
    if (talkMessage.senderId !== props.loginUser.id) {
        return <></>;
    }

    if (
        ((talkMessage.message == null || talkMessage.message.length === 0) &&
            (talkMessage.lstAttachedFileData == null ||
                talkMessage.lstAttachedFileData.length === 0)) ||
        props.talkMessage.displayTime == null ||
        props.talkMessage.id == null ||
        props.partner.gender == null ||
        props.partner.name == null
    ) {
        return <></>;
    }
    const regExp = /(https?:\/\/\S+)/g;
    const partner = props.partner;
    const partnerId = partner.id;
    const gender = props.loginUser.gender;
    const name = props.loginUser.name;
    const message = talkMessage.message;
    const displayTime = talkMessage.displayTime;
    const existAttachedFile = talkMessage.existsAttachedFiles;
    const lstAttachedFile = talkMessage.lstAttachedFileData;
    const read = talkMessage.read;

    return (
        <React.Fragment>
            {partner != null &&
                partnerId != null &&
                gender != null &&
                name != null &&
                message != null &&
                displayTime != null && (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            paddingBottom: "10px",
                            columnGap: "5px",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "flex-start",
                                paddingBottom: "10px",
                                columnGap: "5px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                }}
                            >
                                {
                                    // 時刻
                                }
                                <Box
                                    sx={{
                                        fontSize: "0.7rem",
                                        color: AppConstants.COLOR_GRAY_DATE,
                                    }}
                                >
                                    {displayTime}
                                </Box>
                                {
                                    // 未読
                                }
                                {read !== 1 && (
                                    <Box
                                        sx={{
                                            fontSize: "0.8rem",
                                            color: "#ffffff",
                                            backgroundColor:
                                                AppConstants.BG_COLOR_UNREAD_LABEL,
                                            padding: "2px 10px",
                                            borderRadius: "9999px",
                                        }}
                                    >
                                        <FormattedMessage id={"unread"} />
                                    </Box>
                                )}
                            </Box>
                            {
                                // メッセージ
                            }
                            {message != null && message.length > 0 && (
                                <Box
                                    sx={{
                                        position: "relative",
                                        color: "#ffffff",
                                        padding: "5px 10px",
                                        borderRadius: "10px",
                                        textAlign: "left",
                                        maxWidth: "40vw",
                                        backgroundColor:
                                            gender === DbConstants.GENDER_MALE
                                                ? AppConstants.BG_COLOR_GENDER_MALE
                                                : gender ===
                                                  DbConstants.GENDER_FEMALE
                                                ? AppConstants.BG_COLOR_GENDER_FEMALE
                                                : AppConstants.BG_COLOR_GENDER_OTHER,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: "5px",
                                            right: "-9px",
                                            width: "10px",
                                            height: "15px",
                                        }}
                                    >
                                        <svg 
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            className="svg"
                                            viewBox="0 0 10 15"
                                        >
                                            <path d="M 0,10 Q 10,10 10,0 Q 5,5 0,0 L 0 10" stroke="none" fill={gender ===
                                            DbConstants.GENDER_MALE
                                                ? AppConstants.BG_COLOR_GENDER_MALE
                                                : gender ===
                                                    DbConstants.GENDER_FEMALE
                                                ? AppConstants.BG_COLOR_GENDER_FEMALE
                                                : AppConstants.BG_COLOR_GENDER_OTHER} />
                                        </svg>
                                    </Box>
                                    {message.split("\n").map((text, index) => {
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "flex-start",
                                                }}
                                            >
                                                {reactStringReplace(
                                                    text,
                                                    regExp,
                                                    (match, i) => (
                                                        <React.Fragment
                                                            key={`${index}_${i}`}
                                                        >
                                                            <a
                                                                target="_blank"
                                                                href={match}
                                                                style={{
                                                                    color: "#ffffff",
                                                                    wordBreak:
                                                                        "break-all",
                                                                }}
                                                                rel="noreferrer"
                                                            >
                                                                {match}
                                                            </a>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}
                            {
                                // 三点
                            }
                            <IconButton onClick={props.onClickDelete}>
                                <MoreHorizIcon />
                            </IconButton>
                        </Box>
                        {
                            // 添付ファイル
                        }
                        <Box className="attached-file-area"
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column",
                                    sm: "row",
                                },
                                flexWrap: "wrap",
                                justifyContent: "flex-start",
                                alignItems: "flex-end"
                            }}
                        >
                            {existAttachedFile === 1 &&
                                lstAttachedFile != null &&
                                lstAttachedFile.length > 0 && (
                                    <AttachedFileListForChatComponent
                                        loginUser={props.loginUser}
                                        lstAttachedFile={lstAttachedFile}
                                        isPartner={false}
                                        partnerId={partnerId}
                                    />
                                )}
                        </Box>
                    </Box>
                )}
        </React.Fragment>
    );
};

/**
 * パートナーメッセージコンポーネント
 */
type PartnerTalkMessageProps = {
    loginUser: UserModel;
    talkMessage: TalkMessageModel;
    partner: UserModel;
    onRead: () => void;
};
const PartnerTalkMessage: React.FC<PartnerTalkMessageProps> = (props) => {
    const talkMessage = props.talkMessage;
    if (talkMessage == null) {
        return <></>;
    }
    if (talkMessage.receiverId !== props.loginUser.id) {
        return <></>;
    }
    if (talkMessage.read !== 1) {
        props.onRead();
    }
    if (
        ((talkMessage.message == null || talkMessage.message.length === 0) &&
            (talkMessage.lstAttachedFileData == null ||
                talkMessage.lstAttachedFileData.length === 0)) ||
        props.talkMessage.displayTime == null ||
        props.partner.gender == null ||
        props.partner.name == null
    ) {
        return <></>;
    }
    const regExp = /(https?:\/\/\S+)/g;
    const partner = props.partner;
    const partnerId = partner.id;
    const gender = props.partner.gender;
    const name = props.partner.name;
    const iconName = props.partner.iconName;
    const message = talkMessage.message;
    const displayTime = talkMessage.displayTime;
    const existAttachedFile = talkMessage.existsAttachedFiles;
    const lstAttachedFile = talkMessage.lstAttachedFileData;
    const read = talkMessage.read;

    return (
        <React.Fragment>
            {partner != null &&
                partnerId != null &&
                gender != null &&
                name != null &&
                message != null &&
                displayTime != null && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            paddingBottom: "10px",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                columnGap: "5px",
                            }}
                        >
                            {
                                // プロフィール画像
                            }
                            <a
                                target="_blank"
                                href={`/users/${Utility.getUserKey(
                                    partner
                                )}/profile
                                    `}
                                style={{ cursor: "pointer" }}
                                rel="noreferrer"
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        objectFit: "cover",
                                        height: {
                                            xs: "20px",
                                            sm: "30px",
                                        },
                                        width: {
                                            xs: "20px",
                                            sm: "30px",
                                        },
                                        borderRadius: "50%",
                                    }}
                                    src={
                                        iconName != null && iconName.length > 0
                                            ? UrlConstants.URL_S3_USER_ICON +
                                              iconName
                                            : "/images/no_image.png"
                                    }
                                />
                            </a>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        columnGap: "5px",
                                    }}
                                >
                                    {
                                        // メッセージ
                                    }
                                    {message != null && message.length > 0 && (
                                        <Box
                                            sx={{
                                                position: "relative",
                                                left: "10px",
                                                color: "#ffffff",
                                                padding: "5px 10px",
                                                borderRadius: "10px",
                                                textAlign: "left",
                                                maxWidth: "40vw",
                                                backgroundColor:
                                                    gender ===
                                                    DbConstants.GENDER_MALE
                                                        ? AppConstants.BG_COLOR_GENDER_MALE
                                                        : gender ===
                                                          DbConstants.GENDER_FEMALE
                                                        ? AppConstants.BG_COLOR_GENDER_FEMALE
                                                        : AppConstants.BG_COLOR_GENDER_OTHER,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    top: "5px",
                                                    left: "-9px",
                                                    width: "10px",
                                                    height: "15px",
                                                }}
                                            >
                                                <svg 
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                    className="svg"
                                                    viewBox="0 0 10 15"
                                                >
                                                    <path d="M 10,10 Q 0,10 0,0 Q 5,5 10,0 L 10,10" stroke="none" fill={gender ===
                                                    DbConstants.GENDER_MALE
                                                        ? AppConstants.BG_COLOR_GENDER_MALE
                                                        : gender ===
                                                          DbConstants.GENDER_FEMALE
                                                        ? AppConstants.BG_COLOR_GENDER_FEMALE
                                                        : AppConstants.BG_COLOR_GENDER_OTHER} />
                                                </svg>
                                            </Box>

                                            {message
                                                .split("\n")
                                                .map((text, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                justifyContent:
                                                                    "flex-start",
                                                            }}
                                                        >
                                                            {reactStringReplace(
                                                                text,
                                                                regExp,
                                                                (match, i) => (
                                                                    <React.Fragment
                                                                        key={`${index}_${i}`}
                                                                    >
                                                                        <a
                                                                            target="_blank"
                                                                            href={
                                                                                match
                                                                            }
                                                                            style={{
                                                                                color: "#ffffff",
                                                                                wordBreak:
                                                                                    "break-all",
                                                                            }}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {
                                                                                match
                                                                            }
                                                                        </a>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                    )}
                                    {
                                        // 時刻
                                    }
                                    <Box
                                        sx={{
                                            fontSize: "0.7rem",
                                            color: AppConstants.COLOR_GRAY_DATE,
                                        }}
                                    >
                                        {displayTime}
                                    </Box>
                                    {
                                        // 未読
                                    }
                                    {read !== 1 && (
                                        <Box
                                            sx={{
                                                fontSize: "0.8rem",
                                                color: "#ffffff",
                                                backgroundColor:
                                                    AppConstants.BG_COLOR_UNREAD_LABEL,
                                                padding: "2px 10px",
                                                borderRadius: "9999px",
                                            }}
                                        >
                                            <FormattedMessage id={"unread"} />
                                        </Box>
                                    )}
                                </Box>
                                {
                                    // 添付ファイル
                                }
                                <Box className="attached-file-area partner"
                                    sx={{
                                        display: "flex",
                                        flexDirection: {
                                            xs: "column",
                                            sm: "row",
                                        },
                                        flexWrap: "wrap",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    {existAttachedFile === 1 &&
                                        lstAttachedFile != null &&
                                        lstAttachedFile.length > 0 && (
                                            <AttachedFileListForChatComponent
                                                loginUser={props.loginUser}
                                                lstAttachedFile={lstAttachedFile}
                                                isPartner={true}
                                                partnerId={partnerId}
                                            />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}
        </React.Fragment>
    );
};

export const UserChatListItemComponent = React.memo(
    UserChatListItem,
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.partner !== nextProps.partner) {
            return false;
        }
        if (prevProps.partner != null && nextProps.partner != null) {
            if (prevProps.partner.id !== nextProps.partner.id) {
                return false;
            }
        }
        if (prevProps.talkMessage !== nextProps.talkMessage) {
            return false;
        }
        if (prevProps.talkMessage != null && nextProps.talkMessage != null) {
            if (prevProps.talkMessage.id !== nextProps.talkMessage.id) {
                return false;
            }
        }
        if (prevProps.timeDifference !== nextProps.timeDifference) {
            return false;
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }
        if (prevProps.visible !== nextProps.visible) {
            return false;
        }
        if (prevProps.read !== nextProps.read) {
            return false;
        }

        return true;
    }
);
