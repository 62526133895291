export enum USER_LIST_TYPE {
    LIST,
    SEARCH,
}
export enum MYPAGE_ITEM {
    USERNAME,
    PROFILE_PICTURE,
    PROFILE,
    GENDER,
    AGE,
    ATTRIBUTE,
    REGION,
    TAG,
    NOTIFICATION,
}

export enum GROUP_LIST_TYPE {
    JOIN,
    UNDER_APPLIED,
    APPLYING,
    UNDER_INVITED,
    INVITING,
}

export enum GROUP_JOIN_STATUS {
    JOINED,
    APPLYING,
    APPLIED,
    INVITING,
    INVITED,
}

export enum JOIN_GROUP_ACTION {
    JOIN,
    APPLY_FOR_MEMBERSHIP,
    ACCEPT_INVITATION,
    DECLINE_INVITATION,
    WITHDRAW_INVITING,
    APPROVE_APPLICATION,
    DECLINE_APPLICATION,
    WITHDRAW_APPLYING,
    LEAVE_GROUP,
    DISBAND_GROUP,
}

export enum TALK_TYPE {
    NONE,
    VOICE,
    VIDEO,
}
export enum TALK_STATUS {
    NONE,
    SENT_REQUEST_CALL,
    RECEIVED_RESPONSE_OF_REQUEST_CALL,
    UNDER_TALK,
    PAUSE_TALK,
}

export enum REQUEST_TALK_RESPONSE {
    NONE,
    NO_RESPONSE,
    CANCEL,
    BLOCKED,
    AGREED,
}
export enum SHOOT_TYPE {
    NONE,
    THUMBNAIL,
    PHOTO,
    VIDEO,
}
export enum SHOOT_MODE {
    NONE,
    SHOOT_PHOTO,
    SHOOT_VIDEO,
    RECORDING,
    FINISHED,
}
export enum ALBUM_TYPE {
    NONE,
    PUBLIC,
    FOLLOWER,
    GROUP,
    USER,
    PRIVATE,
}